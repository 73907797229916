<template>
  <div className="animated fadeIn">

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

        <template slot="h__id">
          <div class="text-center">
           <input type='checkbox' id='checkbox' @click='selectAll()'>
            </div>
        </template>

        <div slot="id" slot-scope="props">
            <input type="checkbox" class="form-control" v-model='props.row.isChecked'>
        </div>

          <!--<template slot="status" slot-scope="data">
            <b-badge :variant="getStatus(data.status)">{{data.status}}</b-badge>
          </template>-->

          <template slot="actions" slot-scope="props">
            <b-button variant="outline-dark" class="btn-sm mr-2" :to="{ name: 'Policy', params: { id: props.row.id } }">View</b-button>
          </template>

      </v-server-table>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'
  import { setupCalendar, DatePicker } from 'v-calendar'
  import 'v-calendar/lib/v-calendar.min.css'
  Vue.use(ServerTable)

  setupCalendar({
    firstDayOfWeek: 2 // Monday
  })

  const today = new Date();

  let url = null;
  if (localStorage.getItem('selected_product')) {
    url = '/policies/?product=' + localStorage.getItem('selected_product');
  } else {
    url = '/policies/';
  }

  export default {
    name: 'DataTable',
    components: {
      ServerTable,
      'v-date-picker': DatePicker
    },
    data: function () {
      return {
        get_document: false,
        show_export: false,
        start_date: null,
        end_date: null,
        original: '',
        exportoptions: [
          {value:'', text:'Latest Version'},
          {value:'original', text:'Version One (Original)'},
        ],
        attrs: [
          {
            key: 'today',
            dot: true,
            dates: new Date(),
          },
        ],
        columns: [
          'reference', 'product', 'number_of_pets', 'status', 'actions',
        ],
        data: [],
        checkedData: [],
        fields: [
          /*{
          name: '__checkbox',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        },*/
        ],
        options: {
          requestFunction: (data) => {
              return axios.get(url, {
                  params: data
              }).catch(error => {

                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('post error');
                console.log(error.response);
            });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
            policy_number: 'Reference',
            insurer: 'Insurer Code',
            version: 'Version',
            status: 'Status',
            created: 'Created'
          },
          filterable: [
            //'reference', 'product', 'number_of_pets', 'status',
          ],
          sortable: [
            //'reference', 'product', 'number_of_pets', 'status',
          ],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      resetStartDate() {
        this.start_date = null
      },
      resetEndDate() {
        this.end_date = null
      },
      selectAll() {
           let length = this.tableData.length
           this.isRead=!this.isSelected;

           for (let i = 0; i < length; i++) {
               this.tableData[i].isChecked=this.isSelected;
           }
      },
      getStatus (status) {
        return status === null ? 'success' : status === 1 ? 'warning' : status === 'Banned' ? 'danger' : 'primary'
      },
      getPolicies() {
        axios.get(url).then(
          response => (
            this.data = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.data
      },
      toggleExport() {
        this.show_export = !this.show_export
      },
      downloadCSV() {

        this.get_document = true;

        axios({
          url: '/policies/export/?type=date_range&created_after=' + this.exportRangeStart + '&created_before=' + this.exportRangeEnd + '&original=' + this.original + '&product=' + localStorage.getItem('selected_product'),
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', "application-export.csv");
          document.body.appendChild(link);
          link.click();
          this.get_document = false;
        }).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });

      }
    },
    computed: {
      exportStartDate: function () { return new Date(this.start_date).toLocaleDateString('en-GB') },
      exportEndDate: function () { return new Date(this.end_date).toLocaleDateString('en-GB') },
      exportRangeStart: function () {
        var date = new Date(this.start_date).toLocaleDateString('en-GB');

        var string_date = ''
        string_date += date.substring(6, 10);
        string_date += '-';
        string_date += date.substring(3, 5);
        string_date += '-';
        string_date += date.substring(0, 2);
        console.log(string_date)
        return string_date
      },
      exportRangeEnd: function () {
        var date = new Date(this.end_date).toLocaleDateString('en-GB');

        var string_date = ''
        string_date += date.substring(6, 10);
        string_date += '-';
        string_date += date.substring(3, 5);
        string_date += '-';
        string_date += date.substring(0, 2);
        console.log(string_date)
        return string_date
      },
      validExportFields: function(){
       return this.start_date && this.end_date;
      },
      user_permissions() {
        return this.$store.getters.getUserPermissions
      },
      hasAccess() {

        let access = ['full_admin', 'customer_services'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      hasExportAccess() {

        let access = ['full_admin', 'data_management',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      }
    },
    mounted() {
      //this.getPolicies()
    }
  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }

  .form-inline label {
    align-items: left;
  }


</style>
